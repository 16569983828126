import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AppSettings } from '@configs/app.setting'
import { AppGuard } from '@core/guards/auth.guard'
import { AdminBusinessDetail, Status } from 'src/app/admin/business/ui/admin-business.model'
import { Pagination, ReqPagination, ResponseModel } from '@models/common.model'
import { CustomCookieService } from '@services/cookie/cookie.service'
import { getAuthKey } from '@core/utils/auth-stuff'
import { Observable } from 'rxjs'
import { tap, map } from 'rxjs/operators'
import { BranchRepositoryService } from 'src/app/feature/branch/data-access/repository/branch-repository.service'
import { StoreRepositoryService } from 'src/app/feature/branch/data-access/repository/store-repository/store-repository.service'
import { DokuPaymentPayload } from 'src/app/admin/business/interface/business.model'

@Injectable({
  providedIn: 'root',
})
export class AdminBusinessRespositoryService {
  constructor(
    private _httpClient: HttpClient,
    private _branchRepository: BranchRepositoryService,
    private _storeRepository: StoreRepositoryService,
    private _customCookieService: CustomCookieService,
    protected appGuard: AppGuard
  ) {}

  adminBusinessList(pageConfig: ReqPagination | any): Observable<Pagination> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    headers = headers.set('Authorization', 'Bearer ' + getAuthKey(this._customCookieService))
    let url: any = `${AppSettings.ADMIN_BUSINESS_URL}?`
    for (const config in pageConfig) {
      if (url.charAt(url.length - 1) === '?') {
        url = `${url}${config}=${pageConfig[config]}`
      } else {
        url = `${url}&${config}=${pageConfig[config]}`
      }
    }
    return this._httpClient.get(url, { headers: headers }).pipe(
      map((response: Pagination | any) => response),
      tap({
        next: (response) => response,
        error: (err: any) => {
          if (err instanceof HttpErrorResponse) this.appGuard.checkAccess(err.status)
        },
      })
    )
  }

  businessList(pageConfig: ReqPagination | any, businessId?: number): Observable<Pagination> {
    // Used by monitored business dropdown

    let adminPrivilage: any = localStorage.getItem('admin-privilage')
    adminPrivilage = JSON.parse(adminPrivilage)

    // Use store list if user is not super admin
    if (!adminPrivilage || !businessId) {
      return this._branchRepository.branchStoreList(pageConfig)
    }

    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    headers = headers.set('Authorization', 'Bearer ' + getAuthKey(this._customCookieService))
    let url: any = `${AppSettings.BUSINESS_UTILITIES_URL}/${AppSettings.BUSINESS_UTILITIES_LIST}?`
    for (const config in pageConfig) {
      if (url.charAt(url.length - 1) === '?') {
        url = `${url}${config}=${pageConfig[config]}`
      } else {
        url = `${url}&${config}=${pageConfig[config]}`
      }
    }

    if (businessId) {
      url = `${url}&businessId=${businessId}`
    }
    return this._httpClient.get(url, { headers: headers }).pipe(
      map((response: Pagination | any) => response),
      tap({
        next: (response) => response,
        error: (err: any) => {
          if (err instanceof HttpErrorResponse) this.appGuard.checkAccess(err.status)
        },
      })
    )
  }

  getMerchantPaymentIntegration(businessId: number, storeId: number, region: string): Observable<Pagination> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    headers = headers.set('Authorization', 'Bearer ' + getAuthKey(this._customCookieService))
    let url: string = `${AppSettings.ADMIN_BUSINESS_SECTION_URL}${businessId}/integration/payment?storeId=${storeId}&region=${region}`

    return this._httpClient.get(url, { headers: headers }).pipe(
      map((response: Pagination | any) => response),
      tap({
        next: (response) => response,
        error: (err: any) => {
          if (err instanceof HttpErrorResponse) this.appGuard.checkAccess(err.status)
        },
      })
    )
  }

  addAdminBusiness(adminBusiness: AdminBusinessDetail): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    headers = headers.set('Authorization', 'Bearer ' + getAuthKey(this._customCookieService))
    return this._httpClient
      .post(AppSettings.ADMIN_BUSINESS_URL, adminBusiness, {
        headers: headers,
      })
      .pipe(
        map((response: any) => response),
        tap({
          next: (response) => response,
          error: (err: any) => {
            if (err instanceof HttpErrorResponse) this.appGuard.checkAccess(err.status)
          },
        })
      )
  }

  activateBusiness(businessId: number): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    headers = headers.set('Authorization', 'Bearer ' + getAuthKey(this._customCookieService))
    return this._httpClient
      .post(
        `${AppSettings.ADMIN_BUSINESS_URL}/${businessId}/activate-account`,
        {},
        {
          headers: headers,
        }
      )
      .pipe(
        map((response: any) => response),
        tap({
          next: (response) => response,
          error: (err: any) => {
            if (err instanceof HttpErrorResponse) this.appGuard.checkAccess(err.status)
          },
        })
      )
  }

  getAdminBusinessById(businessId: number): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    headers = headers.set('Authorization', 'Bearer ' + getAuthKey(this._customCookieService))
    const country: string = localStorage.getItem('country')!
    let url: string = ''

    url = `${AppSettings.ADMIN_BUSINESS_URL}/${businessId}`

    return this._httpClient
      .get(url, {
        headers: headers,
      })
      .pipe(
        map((response: any) => response),
        tap({
          next: (response) => response,
          error: (err: any) => {
            if (err instanceof HttpErrorResponse) this.appGuard.checkAccess(err.status)
          },
        })
      )
  }

  updateAdminBusiness(businessId: number, newAdminBusiness: AdminBusinessDetail): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    headers = headers.set('Authorization', 'Bearer ' + getAuthKey(this._customCookieService))
    return this._httpClient
      .put(`${AppSettings.ADMIN_BUSINESS_URL}/${businessId}`, newAdminBusiness, {
        headers: headers,
      })
      .pipe(
        map((response: any) => response),
        tap({
          next: (response) => response,
          error: (err: any) => {
            if (err instanceof HttpErrorResponse) this.appGuard.checkAccess(err.status)
          },
        })
      )
  }

  deleteAdminBusiness(businessId: number): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    headers = headers.set('Authorization', 'Bearer ' + getAuthKey(this._customCookieService))
    return this._httpClient
      .delete(`${AppSettings.ADMIN_BUSINESS_URL}/${businessId}`, {
        headers: headers,
      })
      .pipe(
        map((response: any) => response),
        tap({
          next: (response) => response,
          error: (err: any) => {
            if (err instanceof HttpErrorResponse) this.appGuard.checkAccess(err.status)
          },
        })
      )
  }

  resendInvitationBusiness(businessId: number): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + getAuthKey(this._customCookieService))
    return this._httpClient
      .post(`${AppSettings.ADMIN_BUSINESS_URL}/${businessId}/resend-email`, null, {
        headers: headers,
      })
      .pipe(
        map((response: any) => response),
        tap({
          next: (response) => response,
          error: (err: any) => {
            if (err instanceof HttpErrorResponse) this.appGuard.checkAccess(err.status)
          },
        })
      )
  }

  getBusinessInfo(
    businessId,
    isAuth: boolean = false,
    businessDetail: AdminBusinessDetail | undefined,
    isDropdown: boolean = false
  ) {
    // Use store info if user is not super admin
    let adminPrivilage: any = localStorage.getItem('admin-privilage')
    adminPrivilage = JSON.parse(adminPrivilage)
    if (!adminPrivilage || businessDetail?.storeId != undefined) {
      businessId = businessDetail?.storeId != undefined ? businessDetail.storeId : businessId
      return this._storeRepository.getDetailStore(businessId, isAuth, businessDetail!, isDropdown)
    }

    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    headers = headers.set('Authorization', 'Bearer ' + getAuthKey(this._customCookieService))

    return this._httpClient
      .get(
        `${AppSettings.BUSINESS_UTILITIES_URL}/${AppSettings.BUSINESS_UTILITIES_INFO}?${AppSettings.BUSINESS_UTILITIES_ID}=${businessId}`,
        {
          headers: headers,
        }
      )
      .pipe(
        map((response: any) => response),
        tap({
          next: (response) => response,
          error: (err: any) => {
            if (err instanceof HttpErrorResponse) this.appGuard.checkAccess(err.status)
          },
        })
      )
  }

  addDetailedCommission(businessId: number, storeId: number, integrationType: string, config: any): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    headers = headers.set('Authorization', 'Bearer ' + getAuthKey(this._customCookieService))
    return this._httpClient
      .post(
        `${AppSettings.ADMIN_BUSINESS_SECTION_URL}${businessId}/integration/${AppSettings.INTEGRATION_PAYMENT_MENU}/fee?${AppSettings.INTEGRATION_TYPE}=${integrationType}&${AppSettings.ADMIN_BUSINESS_SECTION_STORE_ID}=${storeId}`,
        config,
        {
          headers: headers,
        }
      )
      .pipe(
        map((response: any) => response),
        tap({
          next: (response) => response,
          error: (err: any) => {
            if (err instanceof HttpErrorResponse) this.appGuard.checkAccess(err.status)
          },
        })
      )
  }

  getMerchantPaymentIntegrationDoku(businessId: number): Observable<Pagination> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    headers = headers.set('Authorization', 'Bearer ' + getAuthKey(this._customCookieService))
    let url: string = `api/gateway/admin/business-section/${businessId}/doku/FeeSetting`

    return this._httpClient.get(url, { headers: headers }).pipe(
      map((response: Pagination | any) => response),
      tap({
        next: (response) => response,
        error: (err: any) => {
          if (err instanceof HttpErrorResponse) this.appGuard.checkAccess(err.status)
        },
      })
    )
  }

  setDokuFee(businessId: number, data: DokuPaymentPayload[]): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    headers = headers.set('Authorization', 'Bearer ' + getAuthKey(this._customCookieService))

    let url: any = `/api/gateway/admin/business-section/${businessId}/doku/FeeSetting`

    return this._httpClient.put(url, data, { headers: headers }).pipe(
      map((response: any) => response),
      tap({
        next: (response: any) => response,
        error: (err: any) => {
          if (err instanceof HttpErrorResponse) this.appGuard.checkAccess(err.status)
        },
      })
    )
  }

  getStatusList(): Observable<ResponseModel<Status>> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    headers = headers.set('Authorization', 'Bearer ' + getAuthKey(this._customCookieService))

    let url: any = '/api/merchant/admin/status/list-status-merchant'

    return this._httpClient.get(url, { headers }).pipe(
      map((response: ResponseModel<Status>) => response),
      tap({
        next: (response: ResponseModel<Status>) => response,
        error: (err: any) => {
          if (err instanceof HttpErrorResponse) this.appGuard.checkAccess(err.status)
        },
      })
    )
  }

  getSetupStatusList(): Observable<ResponseModel<Status>> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    headers = headers.set('Authorization', 'Bearer ' + getAuthKey(this._customCookieService))

    let url: any = '/api/merchant/admin/status/list-setUp-status'

    return this._httpClient.get(url, { headers }).pipe(
      map((response: ResponseModel<Status>) => response),
      tap({
        next: (response: ResponseModel<Status>) => response,
        error: (err: any) => {
          if (err instanceof HttpErrorResponse) this.appGuard.checkAccess(err.status)
        },
      })
    )
  }
}
